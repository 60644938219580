<template>
  <div>
    <el-scrollbar>
      <el-main>
        <el-form :model="dataForm" :rules="rules" ref="formLayout" label-width="180px"
                 class="demo-ruleForm form-container"
                 style="margin-top: 20px; width: 99%;">
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="企业名称" prop="companyName">
                <template slot="label">
                  <span>企业名称</span>
                </template>
                <el-input
                  :disabled="true"
                  v-model="dataForm.companyName"
                  controls-position="right"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计月份" prop="statDate">
                <el-date-picker
                  v-model="dataForm.statDate"
                  type="month"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  :disabled="true"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="填报日期" prop="updateTime">
                <template slot="label">
                  <span>填报日期:</span>
                </template>
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="dataForm.updateTime"
                  type="date"
                  disabled="true"
                  placeholder="保存后自动生成"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="自查次数" prop="selfCheckCnt">
                <el-input
                  v-model="dataForm.selfCheckCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.selfCheckCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="互查次数" prop="mutualCheckCnt">
                <el-input
                  v-model="dataForm.mutualCheckCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.mutualCheckCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="危险化学品专项检查次数" prop="dangerousChemicalCheckCnt">
                <el-input
                  v-model="dataForm.dangerousChemicalCheckCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.dangerousChemicalCheckCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="矿山专项检查次数" prop="mineCheckCnt">
                <el-input
                  v-model="dataForm.mineCheckCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.mineCheckCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上级督查下级次数" prop="superiorCnt">
                <el-input
                  v-model="dataForm.superiorCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.superiorCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="政府检查次数" prop="govCnt">
                <el-input
                  v-model="dataForm.govCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.govCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一般隐患项数" prop="generalHDCnt">
                <el-input
                  v-model="dataForm.generalHDCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.generalHDCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一般隐患整改数" prop="generalHDRestructuringCnt">
                <el-input
                  v-model="dataForm.generalHDRestructuringCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.generalHDRestructuringCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重大隐患项数" prop="majorHDCnt">
                <el-input
                  v-model="dataForm.majorHDCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.majorHDCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重大隐患整改数" prop="majorHDRestructuringCnt">
                <el-input
                  v-model="dataForm.majorHDRestructuringCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  style="width: calc(100% - 30px)"
                  :readonly="type == 'view'"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.majorHDRestructuringCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一般隐患整改率" prop="generalHDRectifiedRate">
                <el-input
                  v-model="generalHDRectifiedRate"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :disabled="true"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.generalHDRectifiedRateS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重大隐患整改率" prop="majorHDRectifiedRate">
                <el-input
                  v-model="majorHDRectifiedRate"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :disabled="true"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.majorHDRectifiedRateS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </el-scrollbar>
    <div class="dialog-footer">
      <el-button v-if="type != 'view'" @click="headSave">保 存</el-button>
      <el-button  @click="headCancel">取 消</el-button>
    </div>
  </div>
</template>
<script>
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {saveOrUpdate, detail, saveSubmitInterface, preserve} from "@/api/report/hazardInvestigation";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {mapGetters} from "vuex";

export default {
  props: {
    rowQuery: {
      type: Object
    }
  },
  name: "protocolEdit",
  mixins: [exForm, draft],
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
      },
      nodeId: '',
      formLoading: false,
      processTemplateKey: "report_fill_hazard_investigation",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      rules: {
        statDate: [
          {required: true, message: '请选择填报年月', trigger: ['blur']},
        ],
        selfCheckCnt: [
          {required: true, message: '请输入自查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        mutualCheckCnt: [
          {required: true, message: '请输入互查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        dangerousChemicalCheckCnt: [
          {required: true, message: '请输入危险化学品专项检查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        mineCheckCnt: [
          {required: true, message: '请输入矿山专项检查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        superiorCnt: [
          {required: true, message: '请输入上级督查下级次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        govCnt: [
          {required: true, message: '请输入政府检查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        generalHDCnt: [
          {required: true, message: '请输入一般隐患项数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        generalHDRestructuringCnt: [
          {required: true, message: '请输入一般隐患整改数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        majorHDCnt: [
          {required: true, message: '请输入重大隐患项数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        majorHDRestructuringCnt: [
          {required: true, message: '请输入重大隐患整改数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        generalHDRectifiedRate: [
          {required: true, message: '请输入一般隐患整改率', trigger: ['blur']},
        ],
        majorHDRectifiedRate: [
          {required: true, message: '请输入重大隐患整改率', trigger: ['blur']},
        ],
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    generalHDRectifiedRate() {
      let generalHDCnt;
      let generalHDRestructuringCnt;
      let number = 0;

      generalHDCnt = (this.dataForm.generalHDCnt) && (Number((this.dataForm.generalHDCnt).toString().replace(/\D/g, '')) || 0);
      generalHDRestructuringCnt = (this.dataForm.generalHDRestructuringCnt && (Number(this.dataForm.generalHDRestructuringCnt).toString().replace(/\D/g, '')) || 0);

      if (generalHDCnt > 0) {
        number = (generalHDRestructuringCnt / generalHDCnt * 100).toFixed(0);
      } else {
        number = 0;
      }
      if (Number(number) > 100) {
        this.dataForm.generalHDRectifiedRate = 100.00;
        return this.dataForm.generalHDRectifiedRate + "%"
      } else {
        this.dataForm.generalHDRectifiedRate = Number(number);
        return this.dataForm.generalHDRectifiedRate + "%"
      }
    },
    majorHDRectifiedRate() {
      let majorHDCnt;
      let majorHDRestructuringCnt;
      let number = 0;

      majorHDCnt = (this.dataForm.majorHDCnt && (Number(this.dataForm.majorHDCnt).toString().replace(/\D/g, '')) || 0);
      majorHDRestructuringCnt = (this.dataForm.majorHDRestructuringCnt && (Number(this.dataForm.majorHDRestructuringCnt).toString().replace(/\D/g, '')) || 0);

      if (majorHDCnt > 0) {
        number = (majorHDRestructuringCnt / majorHDCnt * 100).toFixed(0);
      } else {
        number = 0;
      }

      if (Number(number) > 100) {
        this.dataForm.majorHDRectifiedRate = 100.00;
        return this.dataForm.majorHDRectifiedRate + "%"
      } else {
        this.dataForm.majorHDRectifiedRate = Number(number);
        return this.dataForm.majorHDRectifiedRate + "%"
      }
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
            {
              label: this.$t('cip.cmn.btn.saveBtn'),
              emit: "head-save",
              type: "button",
              icon: "",
              btnOptType: 'save',
            }
        );
        result.push(
            {
              label: "提交",
              emit: "head-submit",
              type: "button",
              icon: "",
              btnOptType: 'submit',
            }
        );
      }
      return result;
    },
  },
  created() {
    this.type = this.rowQuery.type;
    let row = JSON.parse(this.rowQuery.row);
    this.dataForm.companyName = row.unitName
    this.dataForm.unitCode = row.unitCode
    if(row.id && row.id != -1){
      this.dataForm.id = row.id
      this.initData()
    }else {
      this.dataForm = row;
    }

  },
  methods: {
    generalHDRectifiedRate(value, type) {
      let generalHDCnt;
      let generalHDRestructuringCnt;
      let number = 0;

      generalHDCnt = (type === "generalHDCnt" ? value : this.dataForm.generalHDCnt) && (Number((type === "generalHDCnt" ? value : this.dataForm.generalHDCnt).toString().replace(/\D/g, '')) || 0);
      generalHDRestructuringCnt = (type === "generalHDCnt" ? this.dataForm.generalHDRestructuringCnt : value) && (Number((type === "generalHDCnt" ? this.dataForm.generalHDRestructuringCnt : value).toString().replace(/\D/g, '')) || 0);

      if (generalHDCnt > 0 || generalHDRestructuringCnt > 0) {
        number = (generalHDRestructuringCnt / generalHDCnt * 100).toFixed(2);
      } else {
        number = 0;
      }

      if (Number(number) > 100) {
        this.dataForm.generalHDRectifiedRate = 100.00;
      } else {
        this.dataForm.generalHDRectifiedRate = Number(number);
      }
    },
    majorHDRectifiedRate(value, type) {
      let majorHDCnt;
      let majorHDRestructuringCnt;
      let number = 0;

      majorHDCnt = (type === "majorHDCnt" ? value : this.dataForm.majorHDCnt) && (Number((type === "majorHDCnt" ? value : this.dataForm.majorHDCnt).toString().replace(/\D/g, '')) || 0);
      majorHDRestructuringCnt = (type === "majorHDCnt" ? this.dataForm.majorHDRestructuringCnt : value) && (Number((type === "majorHDCnt" ? this.dataForm.majorHDRestructuringCnt : value).toString().replace(/\D/g, '')) || 0);

      if (majorHDCnt > 0 || majorHDRestructuringCnt > 0) {
        number = (majorHDRestructuringCnt / majorHDCnt * 100).toFixed(2);
      } else {
        number = 0;
      }

      if (Number(number) > 100) {
        this.dataForm.majorHDRectifiedRate = 100.00;
      } else {
        this.dataForm.majorHDRectifiedRate = Number(number);
      }
    },
    initData() {
      detail(this.dataForm).then(res => {
        const item = res.data.data;
        this.dataForm = item;
      })
    },
    headCancel() {
      this.$emit('hazardInvestigationDialog');
    },
    headSave(cancel = false) {
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          if (this.dataForm.statDate) {
            this.$loading();
            if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
              this.dataForm.dataState = '1';
            }
            this.dataForm.processInsId = null
            if(!this.dataForm.dateSource){
              this.dataForm.dateSource = "1"
            }
            preserve(this.dataForm).then(res => {
              if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                this.dataForm = res.data.data;
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.warning(res.data.data);
              }
            })
                .finally(() => {
                  this.$loading().close();
                })
          } else {
            this.$message.warning("统计日期为空");
          }
        }
      })
    },
    headSubmit(row) {
      if (this.dataForm.statDate) {
        this.$refs.formLayout.validate((valid) => {
          if (valid) {
            this.$loading();
            if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
              this.dataForm.dataState = '1';
            }
            saveOrUpdate(this.dataForm, this.type).then(res => {
              if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                this.dataForm = res.data.data;
                this.type = 'edit';
                this.$confirm("确定将提交审核?", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                })
                    .then(() => {
                      this.$loading();
                      let dataForm = this.dataForm
                      this.rpTaskAttributeSubmit(dataForm);
                    }).catch(() => {
                })
                    .finally(() => {
                      this.$loading().close();
                    });
              } else {
                this.$message.warning(res.data.data);
              }
            }).finally(() => {
              this.$loading().close();
            })
          }
        })
      } else {
        this.$message.warning("统计日期为空");
      }
    },
    rpTaskAttributeSubmit(data) {
      this.formProcess.id = data.id;
      this.formProcess.deptId = this.nodeId
      this.handleStartProcess5(true,true).then((res) => {
        this.dataForm.processInsId = res.data.data;
        this.dataForm.dataState = 2;
        saveSubmitInterface(this.dataForm).then((res) => {
          if (res.data.code === 200) {
            this.$emit("closeDialog")
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.initData();
          }
        })
            .catch((err) => {

            })
            .finally(() => {
              this.$loading().close();
            });
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
            (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 70vh;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgb(204, 204, 204);
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
</style>
